import { gql } from '@apollo/client';

export const CREATE_POLL_REPLY = gql`
  mutation createPollReply(
    $pollAnswerId: ID
    $pollQuestionId: ID
    $text: String
  ) {
    createPollReply(
      pollAnswerId: $pollAnswerId
      pollQuestionId: $pollQuestionId
      text: $text
    )
  }
`;

export const CREATE_POLL_REPLIES = gql`
  mutation createPollReplies($pollAnswerIds: [String!]) {
    createPollReplies(pollAnswerIds: $pollAnswerIds)
  }
`;
